import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import './store/auth';
import { useStore } from './store/store';
import { notification } from 'antd';
import Error from './components/Error';
import DatasheetsList from './components/DatasheetsList/DatasheetsList';

const App = (): JSX.Element => {
  const error: string | null = useStore((state) => state.error);
  const isAuth: boolean = useStore((state) => state.isAuthenticated);
  const clearError = useStore((state) => state.clearError);
  const getTokenOrLogin = useStore((state) => state.getTokenOrLogin);

  useEffect(() => {
    if (!isAuth) {
      getTokenOrLogin();
    }
  }, [isAuth, getTokenOrLogin]);

  useEffect(() => {
    if (error) {
      openNotification();
    }
  }, [error]);

  const openNotification = () => {
    notification.error({
      message: 'Error (Please check your permissions)',
      description: error,
      duration: 30,
      onClose: () => clearError(),
    });
  };

  return (
    <Router>
      <Routes>
        <Route path="datasheets/*" element={<DatasheetsList />} />
        <Route path="error/*" element={<Error />} />
        <Route path="*" element={<Navigate to="datasheets" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
