import create, { GetState, SetState } from 'zustand';
import { OpenAPIV3 } from 'openapi-types';
import { KeycloakInstance } from 'keycloak-js';
import { IOIDCConfig } from './models/oidc';
import { IGroup, IRole } from './models/keycloak';
import { createKeycloak, getTokenOrLogin } from './auth';
import { baseGetAuthConfig, baseGetMe, baseGetOpenAPI } from './baseApiActions';
import { IPagination } from './models/pagination';
import { DEFAULTS } from './defaults';
import { IComponent, IDatasheet, IShare, IShareScopes } from './models/dataservice';
import {
  dsGetMongoRes,
  dsGetMongoResNoShare,
  dsListMongoRes,
  dsPatchShareMongoRes,
  dsWriteMongoRes,
} from './dsApiActions';
import { IMe } from './models/auth';
import { kcSearchGroup, kcSearchRole, kcSearchUserById, kcSearchUserByEmail } from './kcApiActions';
import { kiresysDetectImagesInDatasheet } from './kiresysApiActions';
import { IDatasheetDetectedImage } from './models/kiresys';

export interface State {
  me?: IMe;
  oidc?: IOIDCConfig;
  openapi?: OpenAPIV3.Document;
  isAuthenticated: boolean;
  isSystemUser: boolean;
  isSystemRoot: boolean;
  pagination: IPagination;
  pendingApiResponse: boolean;
  isSavePending: boolean;
  error: string | null;
  searchString: string;
  availableGroups?: Array<IGroup>;
  availableRoles?: Array<IRole>;
  pendingAPICall: {
    dsList: boolean;
    dsCount: boolean;
    dsPost: boolean;
    dsGet: boolean;
    dsDelete: boolean;
    dsPut: boolean;
    dsPatch: boolean;
    dsShareGet: boolean;
    dsSharePatch: boolean;
    kcList: boolean;
    kcPost: boolean;
    kcGet: boolean;
    kcDelete: boolean;
    kcPut: boolean;
    kiresys: boolean;
    other: boolean;
  };
  clientId?: string;
  datasheets?: Array<IDatasheet>;
  components?: Array<IComponent>;
  keycloak?: KeycloakInstance;
  selectedSharing?: IShare;
  selectedComponent?: IComponent;
  selectedDatasheet?: IDatasheet;
  detectedDatasheetImages?: IDatasheetDetectedImage[];
}

const _DEFAULTS = DEFAULTS();

export const useStore = create((set: SetState<State>, get: GetState<State>) => ({
  oidc: _DEFAULTS.oidc,
  isAuthenticated: _DEFAULTS.isAuthenticated,
  isSystemUser: _DEFAULTS.isSystemUser,
  isSystemRoot: _DEFAULTS.isSystemRoot,
  pagination: _DEFAULTS.pagination,
  pendingApiResponse: _DEFAULTS.pendingApiResponse,
  isSavePending: _DEFAULTS.isSavePending,
  pendingAPICall: _DEFAULTS.pendingAPICall,
  error: _DEFAULTS.error,
  searchString: _DEFAULTS.searchString,
  me: undefined,
  openapi: undefined,
  keycloak: undefined,
  datasheets: undefined,
  components: undefined,
  clientId: undefined,
  selectedSharing: undefined,
  selectedComponent: undefined,
  selectedDatasheet: undefined,
  detectedDatasheetImages: undefined,

  // Auth
  getTokenOrLogin: async () => {
    await baseGetAuthConfig(set);
    await createKeycloak(set, get);
    await getTokenOrLogin(set, get);
    await baseGetMe(set, get);
  },

  // UX
  clearError: () => {
    set(() => ({ error: null }));
  },

  setSearch: (searchString: string) => {
    set(() => ({ searchString }));
  },

  clearSearch: () => {
    set(() => ({ searchString: '' }));
  },

  clearPagination: () => {
    set((state: State) => ({
      pagination: {
        ...DEFAULTS().pagination,
        pageSize: state.pagination.pageSize,
      },
    }));
  },
  setPageSize: (page: number, pageSize: number) => {
    set((state: State) => {
      const itemCount: number = state.pagination.pageSize * page;
      return {
        pagination: { ...state.pagination, page, pageSize, itemCount },
      };
    });
  },
  setPage: (page: number) => {
    set((state: State) => {
      const itemCount: number = state.pagination.pageSize * page;
      return { pagination: { ...state.pagination, page, itemCount } };
    });
  },
  unselectItem: () =>
    set(() => ({
      selectedDatasheet: undefined,
      selectedSharing: undefined,
      selectedComponent: undefined,
      detectedDatasheetImages: undefined,
    })),

  setSelectedSharingData: (type: 'groups' | 'users' | 'roles' | 'owner', entity: string, scope?: IShareScopes) => {
    if (type === 'owner') {
      set((state: State) => {
        return {
          selectedSharing: { ...state.selectedSharing, owner: entity },
        };
      });
    } else {
      set((state: State) => {
        const entities: { read: string[]; write: string[]; share: string[] } = {
          read: (state.selectedSharing?.[type]?.read || []).filter((i) => i !== entity),
          write: (state.selectedSharing?.[type]?.write || []).filter((i) => i !== entity),
          share: (state.selectedSharing?.[type]?.share || []).filter((i) => i !== entity),
        };
        if (scope) {
          entities[scope].push(entity);
        }

        return {
          selectedSharing: { ...state.selectedSharing, [type]: entities },
        };
      });
    }
  },
  setSelectedDatasheet: (datasheet?: IDatasheet) => {
    set({ selectedDatasheet: datasheet });
  },
  setDetectedDatasheetImages: (images?: IDatasheetDetectedImage[]) => {
    set({ detectedDatasheetImages: images });
  },

  // Base Api
  baseGetAuthConfig: () => baseGetAuthConfig(set),
  baseGetOpenAPI: (path: string) => baseGetOpenAPI(set, get, path),

  // DS API
  dsPatchShareMongoRes: () => dsPatchShareMongoRes(set, get),

  dsListAny: <T>(resourceType: string, query: string) =>
    dsListMongoRes<T>(set, get, resourceType, undefined, undefined, query),
  dsGetAny: <T>(resourceType: string, id: string) => dsGetMongoResNoShare<T>(set, get, resourceType, id, undefined),

  dsListComponents: (query?: string) =>
    dsListMongoRes<IComponent>(set, get, 'components', 'components', undefined, query),
  dsGetComponent: (id: string) => dsGetMongoResNoShare<IComponent>(set, get, 'components', id, 'selectedComponent'),

  dsListDatasheets: (ids: string[]) => {
    const calls: Promise<IDatasheet | void>[] = ids.map((id: string) =>
      dsGetMongoResNoShare<IDatasheet>(set, get, 'object-storage/components-files', id),
    );
    Promise.all(calls).then((res) => {
      const datasheets: IDatasheet[] = res.filter((r) => r && r.type === 'datasheet') as Array<IDatasheet>;
      set({ datasheets });
    });
  },
  dsGetDatasheet: (id: string) =>
    dsGetMongoRes<IDatasheet>(set, get, 'object-storage/components-files', id, 'selectedDatasheet'),
  dsWriteDatasheet: (type: 'create' | 'update' | 'delete') =>
    dsWriteMongoRes(set, get, type, 'object-storage/components-files', 'selectedDatasheet'),

  // KireSys Api
  kiresysDetectImages: (datasheetId: string) => kiresysDetectImagesInDatasheet(set, get, datasheetId),

  // KC Api
  findUserById: (id: string) => kcSearchUserById(set, get, id),
  searchUserByEmail: (searchString: string) => kcSearchUserByEmail(set, get, searchString),
  searchGroup: (searchString: string, includeRoot = false) => kcSearchGroup(set, get, searchString, includeRoot),
  searchRole: (searchString: string) => kcSearchRole(set, get, searchString),
}));
