import { CSSProperties, useEffect, useState } from 'react';
import { Descriptions, Select, Spin, Tooltip } from 'antd';

import { useStore } from '../../store/store';
import { IComponent, IDatasheet } from '../../store/models/dataservice';
import { getComponentFilesURL } from '../../utils/components';
import Placeholder from './Placeholder';
import { getAxiosHeaders } from '../../store/auth';
import axios, { AxiosResponse } from 'axios';
import { convertBlobToBase64 } from '../../utils/formatting';

const { Option } = Select;

interface IProps {
  style?: CSSProperties;
}

const DatasheetViewer = ({ style }: IProps): JSX.Element => {
  const descriptionStyle: CSSProperties = { height: '48px' };

  const isAuth: boolean = useStore((state) => state.isAuthenticated);
  const isGetLoading = useStore((state) => state.pendingAPICall.dsGet);
  const token: string | null = useStore<string | null>((state) => (state.keycloak as any)?.token || null);
  const selectedComponent: IComponent | undefined = useStore((state) => state.selectedComponent);
  const selectedDatasheet: IDatasheet | undefined = useStore((state) => state.selectedDatasheet);
  const datasheets: Array<IDatasheet> = useStore((state) => state.datasheets || []);

  const dsListDatasheets = useStore((state) => state.dsListDatasheets);
  const setSelectedDatasheet = useStore((state) => state.setSelectedDatasheet);
  const setDetectedDatasheetImages = useStore((state) => state.setDetectedDatasheetImages);

  const [datasheetDataUri, setDatasheetDataUri] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isAuth && selectedComponent) {
      const ids: string[] = ((selectedComponent as IComponent).datasheet_files || []).map((d) => d.filename);
      dsListDatasheets(ids);
      setSelectedDatasheet(undefined);
    }
  }, [isAuth, selectedComponent]);

  useEffect(() => {
    if (isAuth && datasheets.length) {
      setSelectedDatasheet(datasheets[0]);
    }
  }, [isAuth, datasheets]);

  useEffect(() => {
    if (isAuth) {
      setDetectedDatasheetImages(undefined);
    }
  }, [isAuth, selectedDatasheet]);

  useEffect(() => {
    const datasheetId: string | undefined = (selectedDatasheet as IDatasheet | undefined)?.id;
    if (isAuth && token && datasheetId) {
      getDatasheetFormData(datasheetId, token);
    }
  }, [isAuth, token, selectedDatasheet]);

  const getDatasheetFormData = async (id: string, access_token: string): Promise<string | void> => {
    if (!(selectedDatasheet as IDatasheet | undefined)?.id) {
      throw new Error('Could not download Datasheet!');
    }
    const url: string = getComponentFilesURL(id);
    const response: AxiosResponse = await axios.get(url, {
      headers: getAxiosHeaders(access_token),
      responseType: 'blob',
    });

    const data: string | null = await convertBlobToBase64(response.data);
    if (!data) {
      return;
    }
    setDatasheetDataUri(data);
    return data;
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', ...(style || {}) }}>
      <Descriptions bordered column={1} layout="horizontal" size="small" labelStyle={{ width: '120px' }}>
        <Descriptions.Item label="Selected Part" style={descriptionStyle}>
          {!isGetLoading && (
            <Tooltip title={(selectedComponent as IComponent | undefined)?.id}>
              {(selectedComponent as IComponent | undefined)?.part_number || 'No part selected'}
            </Tooltip>
          )}
          {isGetLoading && <Spin size="small"></Spin>}
        </Descriptions.Item>
        <Descriptions.Item label="Datasheet" style={descriptionStyle}>
          <Select
            placeholder={isGetLoading ? <Spin size="small"></Spin> : 'No Datasheet found'}
            loading={isGetLoading}
            value={(selectedDatasheet as IDatasheet | undefined)?.id}
            style={{ width: '100%' }}
            onChange={(value) => setSelectedDatasheet(datasheets.filter((d) => d.id === value)[0])}
          >
            {datasheets.map((d) => (
              <Option value={d.id as string} key={d.id as string} disabled={d.type !== 'datasheet'}>
                {d.display_name}
              </Option>
            ))}
          </Select>
        </Descriptions.Item>
      </Descriptions>
      {(selectedDatasheet as IDatasheet | undefined)?.id && (
        <object
          name="file"
          style={{ flex: '1 1 auto', width: '100%', height: 'calc(100% - 8px)', marginTop: '8px' }}
          data={datasheetDataUri}
          type="application/pdf"
          role="document"
          width="100%"
          height="100%"
        />
      )}
      {!(selectedDatasheet as IDatasheet | undefined)?.id && <Placeholder />}
    </div>
  );
};

export default DatasheetViewer;
