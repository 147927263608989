const getBaseApiHost = (): string => {
  const baseApiHostQS: string | null = new URL(document.location as any).searchParams.get('baseApiHost');
  const referrer: string = document.referrer.replace(/\/$/, '');
  const baseApiHost = baseApiHostQS || referrer;
  if (baseApiHost) {
    console.log(`Identified parent Base API Host "${baseApiHost}".`);
    window.localStorage.setItem('celus_appPlugins_baseApiHost', baseApiHost);
    return baseApiHost;
  }
  const previousBaseApiHost: string | null = window.localStorage.getItem('celus_appPlugins_baseApiHost');
  if (previousBaseApiHost) {
    console.log(`Identified previous Base API Host "${previousBaseApiHost}".`);
    return previousBaseApiHost;
  }
  if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
    return 'https://nightly.celus.co';
  }
  return 'https://platform.celus.io';
};
const getKireSysApiHost = (): string => {
  const kiresysApiHostQS: string | null = new URL(document.location as any).searchParams.get('kireSysApiHost');
  if (kiresysApiHostQS) {
    console.log(`Identified KireSys API Host "${kiresysApiHostQS}".`);
    return kiresysApiHostQS;
  }
  if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
    return 'http://127.0.0.1:5000';
  }
  return 'https://kiresys.celus.io';
};
export const baseApiHost: string = getBaseApiHost();
export const kireSysHost: string = getKireSysApiHost();
export const baseApiPath = `${baseApiHost}/api/v2`;
export const kcApiPath = `${baseApiPath}/auth-admin/celus`;
export const dsApiPath = `${baseApiPath}/dataservice`;

// Path to fallback Keycloak auth
export const kcAuthUri = `${window.location.protocol}${window.location.hostname}/auth`;

// Client ID of the app (where resources should be created)
export const kcClientId = 'app-plugins-ui-features-switch';

// Client ID of an open client for login
export const kcLoginClientId = 'public';

// fallback Realm
export const kcRealm = 'celus';

// Prefixes
export const DEFAULT_POLICY_PREFIX = 'RESOURCE_ADMIN';
export const DEFAULT_PERMISSION_PREFIX = 'API_PERMISSION';

// Singe group policies cannot be empty, the ID of a default group to add to policies. Something like an "Admin"
export const kcDefaultGroupInPolicy = '65bb39b9-eb0b-44ad-84b7-7dc199bb0ae8';

export const READ_ONLY_ROLES: string[] = ['default-roles-celus', 'offline_access', 'uma_authorization'];

export enum ROLES {
  SYSTEM_ROOT = 'System Root',
  ADMIN = 'Admin',
  SUPPORT = 'System Support',
}

export enum GROUP_TYPES {
  DEFAULT = 'default',
  ROOT = 'root',
  PUBLIC = 'public',
  DRAFT = 'draft',
  ARCHIVE = 'archive',
  RELEASE = 'release',
  TRAINING = 'training',
  DEMO = 'demo',
}
