import { State } from './store';

export const _DEFAULTS: State = {
  oidc: {},
  isSystemUser: false,
  isSystemRoot: false,
  isAuthenticated: false,
  pagination: { pageSize: 20, page: 1, itemCount: 21 },
  pendingApiResponse: false,
  isSavePending: false,
  pendingAPICall: {
    dsList: false,
    dsCount: false,
    dsPost: false,
    dsGet: false,
    dsPut: false,
    dsDelete: false,
    dsPatch: false,
    dsShareGet: false,
    dsSharePatch: false,
    kcList: false,
    kcPost: false,
    kcGet: false,
    kcDelete: false,
    kcPut: false,
    other: false,
    kiresys: false,
  },
  error: null,
  searchString: '',
};

export const DEFAULTS = (): State => {
  return JSON.parse(JSON.stringify(_DEFAULTS));
};

export const DEFAULT_SEARCHKEYS: Record<string, Array<string>> = {
  'object-storage/components-files': ['display_name'],
  components: ['part_number'],
};
