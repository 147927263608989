import { Button, Image, Tabs } from 'antd';
import { useStore } from '../../store/store';
import { IDatasheet } from '../../store/models/dataservice';
import { IDatasheetDetectedImage } from '../../store/models/kiresys';
import Placeholder from './Placeholder';
import { CSSProperties } from 'react';

const { TabPane } = Tabs;

interface IProps {
  style?: CSSProperties;
}

const DetectImages = ({ style }: IProps): JSX.Element => {
  const tabPaneStyle: CSSProperties = { height: 'calc(100vh - 232px)' };
  const selectedDatasheet: IDatasheet | undefined = useStore((state) => state.selectedDatasheet);
  const detectedImages: IDatasheetDetectedImage[] = useStore((state) => state.detectedDatasheetImages || []);
  const isKireSysLoading = useStore((state) => state.pendingAPICall.kiresys);

  const kiresysDetectImages = useStore((state) => state.kiresysDetectImages);

  return (
    <Tabs defaultActiveKey="1" style={{ height: '100%', ...(style || {}) }}>
      <TabPane tab="Detect Images" key="1" style={tabPaneStyle}>
        <div style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              width: '100%',
              height: '37px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              key="detect_images"
              style={{ margin: '4px' }}
              loading={isKireSysLoading}
              disabled={!(selectedDatasheet as IDatasheet | undefined)?.id}
              onClick={() => kiresysDetectImages((selectedDatasheet as IDatasheet | undefined)?.id || '')}
            >
              Detect Images
            </Button>
          </div>
          <div style={{ width: '100%', height: '200px', flex: '1 1 auto', overflow: 'auto' }}>
            {(detectedImages || []).map((i: IDatasheetDetectedImage) => (
              <Image
                style={{ margin: '0 0 12px 0' }}
                key={i.display_name}
                alt={i.display_name}
                src={i.dataUri}
                fallback="/placeholder.png"
              ></Image>
            ))}
            {!(detectedImages || []).length && <Placeholder />}
          </div>
        </div>
      </TabPane>
      <TabPane tab="Existing Images" key="2" style={tabPaneStyle} disabled>
        Existing Images would come here
      </TabPane>
    </Tabs>
  );
};

export default DetectImages;
