import Search from 'antd/es/input/Search';
import { Button, Dropdown, Menu, Space } from 'antd';
import { useStore } from '../../store/store';
import { MenuOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import './MainList.css';

const routesThatCanAdd: Array<string> = [];
const routesThatCanAddAsSysUser: Array<string> = [];
const routesThatCanSearch: Array<string> = ['/datasheets'];

const MainListExtra = (): JSX.Element => {
  const setSearch = useStore((state) => state.setSearch);
  const clearSearch = useStore((state) => state.clearSearch);
  const clearPagination = useStore((state) => state.clearPagination);
  const searchString: string = useStore((state) => state.searchString);
  const pendingApiResponse: boolean = useStore((state) => state.pendingApiResponse || state.pendingAPICall.dsList);
  const isSystemUser: boolean = useStore<boolean>((state) => state.isSystemUser);
  const { pathname: loc } = useLocation();
  const navigate = useNavigate();
  const canAdd: boolean = (isSystemUser ? routesThatCanAddAsSysUser : routesThatCanAdd).includes(loc);
  const canSearch: boolean = routesThatCanSearch.includes(loc);

  const handleChangeView = (path: string) => {
    clearSearch();
    clearPagination();
    navigate(path);
  };

  const menuOptions: Array<{ id: string; path: string; label: string; icon: React.ReactNode; hidden: boolean }> = [
    {
      id: 'datasheets',
      path: '/datasheets',
      label: 'Datasheets',
      icon: <UserOutlined />,
      hidden: false,
    },
  ];

  return (
    <Space style={{ display: 'flex', alignItems: 'center', maxWidth: '50vw' }}>
      {canSearch && (
        <Search
          value={searchString}
          onChange={({ target }) => setSearch(target.value)}
          placeholder="search"
          loading={pendingApiResponse}
          allowClear
          style={{ width: '300px', maxWidth: 'calc(50vw - 90px)', minWidth: '100px' }}
        />
      )}
      <Dropdown
        overlay={
          <Menu>
            {menuOptions
              .filter((option) => !option.hidden)
              .map((option) => (
                <Menu.Item
                  key={option.id}
                  icon={option.icon}
                  disabled={loc.startsWith(option.path)}
                  onClick={() => handleChangeView(option.path)}
                >
                  {option.label}
                </Menu.Item>
              ))}
          </Menu>
        }
      >
        <Button icon={<MenuOutlined />} size="middle" />
      </Dropdown>
      {canAdd && (
        <Button title="Add new" icon={<PlusOutlined />} size="middle" onClick={() => navigate(`${loc}/add`)} />
      )}
    </Space>
  );
};

export default MainListExtra;
