import { IGroup, RawGroup } from '../store/models/keycloak';

const flattenSubgroups = (groups: Array<RawGroup>): Array<IGroup> => {
  let flattenedGroups: Array<IGroup> = [];
  for (const group of groups) {
    flattenedGroups = [...flattenedGroups, group as IGroup];
    if (group.subGroups.length) {
      const subGroups = flattenSubgroups(group.subGroups);
      flattenedGroups = [...flattenedGroups, ...subGroups];
    }
  }
  return flattenedGroups;
};

export const flattenGroups = (groups: Array<RawGroup>, includeRoot: boolean): Array<IGroup> => {
  const flattenedGroups: Array<IGroup> = groups.reduce(
    (prev: Array<IGroup>, curr: RawGroup) => [...prev, ...flattenSubgroups([curr])],
    [],
  );
  if (!includeRoot) {
    return flattenedGroups.filter((g) => g.path.split('/').length > 2);
  }
  return flattenedGroups;
};
