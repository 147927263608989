import { GetState, SetState } from 'zustand';
import axios, { AxiosResponse } from 'axios';
import * as zip from '@zip.js/zip.js';
import * as mime from 'mime';

import { State } from './store';
import { getAxiosHeaders } from './auth';
import { getComponentFilesURL } from '../utils/components';
import { IDatasheetDetectedImage, IUnzippedBlob } from './models/kiresys';
import { kireSysHost } from '../constants';

const handleError = (error: unknown, set: SetState<State>, message: string) => {
  console.error(error);
  set((state: State) => ({
    pendingAPICall: { ...state.pendingAPICall, kiresys: false },
    error: message,
  }));
};

const getDatasheetFormData = async (datasheetId: string, token: string): Promise<FormData> => {
  const pdfUrl: string = getComponentFilesURL(datasheetId);
  const response: AxiosResponse = await axios.get(pdfUrl, { headers: getAxiosHeaders(token), responseType: 'blob' });

  const formData = new FormData();
  formData.append('file', response.data);
  return formData;
};

const detectImagesInDatasheet = async (formData: FormData, token: string): Promise<Blob> => {
  const imageUrl = `${kireSysHost}/detect/images`;
  const response: AxiosResponse = await axios.post(imageUrl, formData, {
    headers: getAxiosHeaders(token, { 'Content-Type': 'multipart/form-data' }),
    responseType: 'arraybuffer',
  });
  return new Blob([response.data]);
};

const unzipBlob = async (blob: Blob): Promise<IUnzippedBlob[]> => {
  const reader = new zip.ZipReader(new zip.BlobReader(blob));
  const entries: zip.Entry[] = await reader.getEntries();
  const unzippedBlob: IUnzippedBlob[] = [];
  for (const entry of entries || []) {
    if (!entry?.getData) {
      continue;
    }
    const mimeType: string | undefined = mime.getType(entry.filename) || undefined;
    const dataUri = await entry.getData(new zip.Data64URIWriter(mimeType));
    unzippedBlob.push({
      filename: entry.filename,
      dataUri,
    });
  }
  await reader.close();
  return unzippedBlob;
};

export const kiresysDetectImagesInDatasheet = async (
  set: SetState<State>,
  get: GetState<State>,
  datasheetId: string,
): Promise<void> => {
  const state: State = get();
  const token: string | undefined = state.keycloak?.token;
  if (!token || state.pendingAPICall.kiresys) {
    return;
  }
  set((state: State) => ({ pendingAPICall: { ...state.pendingAPICall, kiresys: true } }));

  let formData: FormData;
  let imagesBlob: Blob;
  let images: IDatasheetDetectedImage[];
  try {
    formData = await getDatasheetFormData(datasheetId, token);
  } catch (err) {
    return handleError(err, set, `Failed to fetch datasheet ${datasheetId} from server.`);
  }

  try {
    imagesBlob = await detectImagesInDatasheet(formData, token);
  } catch (err) {
    return handleError(err, set, `Failed to detect images in datasheet ${datasheetId}.`);
  }

  try {
    const unzipped: IUnzippedBlob[] = await unzipBlob(imagesBlob);
    images = unzipped
      .filter((b) => b.filename.split('.').pop() === 'png')
      .map((b) => ({ display_name: b.filename, dataUri: b.dataUri, saved: false }));
  } catch (err) {
    return handleError(err, set, `Failed to unzip detected images for datasheet ${datasheetId}.`);
  }

  set((state: State) => ({
    pendingAPICall: { ...state.pendingAPICall, kiresys: false },
    detectedDatasheetImages: images,
  }));
};
