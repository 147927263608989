import { IPaginatedResponse } from './models/keycloak';
import { getAuthHeaders } from './auth';
import { kcApiPath } from '../constants';
import { IPagination } from './models/pagination';

export interface RequestInitWithTimeout extends RequestInit {
  timeout?: number;
}

const fetchWithTimeout = async (input: RequestInfo, init?: RequestInitWithTimeout): Promise<Response> => {
  const timeout: number = init?.timeout || 30000;
  if (init?.timeout) {
    delete init.timeout;
  }

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(input, {
    ...init,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};

export const getNewItemCount = (pagination: IPagination, dataLength: number): number => {
  const zeroBasedPage: number = pagination.page - 1;
  let newCount: number = pagination.pageSize * zeroBasedPage + dataLength;
  if (dataLength === pagination.pageSize) {
    newCount++;
  }
  return newCount;
};

export const KCPaginatedList = <T>(
  path: string,
  pagination: IPagination,
  token: string | null = null,
): Promise<IPaginatedResponse<T>> => {
  return new Promise<IPaginatedResponse<T>>((resolve, reject) => {
    const zeroBasedPage: number = pagination.page - 1;
    const first: number = zeroBasedPage * pagination.pageSize;
    const pathWithPagination = `${path}${path.includes('?') ? '&' : '?'}max=${pagination.pageSize}&first=${first}`;
    KCGet<Array<T>>(pathWithPagination, token, true)
      .then((data: Array<T>) => {
        pagination.itemCount = getNewItemCount(pagination, data.length);
        resolve({ data, pagination });
      })
      .catch(reject);
  });
};

export const KCGet = <T>(path: string, token: string | null = null, withJson = true, timeout = 10000): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fetchWithTimeout(`${kcApiPath}${path}`, { headers: getAuthHeaders(token), timeout })
      .then((res: Response) => {
        if (res.status > 299) {
          return reject();
        }
        if (withJson) {
          return res.json().then(resolve).catch(reject);
        }
        resolve(res.body as unknown as T);
      })
      .catch(reject);
  });
};

export const KCBody = <T>(
  method: string,
  token: string | null = null,
  path: string,
  data?: unknown,
  withJson = false,
): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fetch(`${kcApiPath}${path}`, {
      method: method,
      headers: {
        ...getAuthHeaders(token),
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
      body: data ? JSON.stringify(data) : undefined,
    })
      .then((res: Response) => {
        if (res.status > 299) {
          return reject();
        }
        if (withJson) {
          return res.json().then(resolve).catch(reject);
        }
        resolve(res.body as unknown as T);
      })
      .catch(reject);
  });
};

export const KCPut = <T>(path: string, token: string | null = null, data?: unknown): Promise<T> =>
  KCBody<T>('PUT', token, path, data);

export const KCPost = <T>(path: string, token: string | null = null, data?: unknown, withJson = false): Promise<T> =>
  KCBody<T>('POST', token, path, data, withJson);

export const KCDelete = <T>(path: string, token: string | null = null, data?: unknown, withJson = false): Promise<T> =>
  KCBody<T>('DELETE', token, path, data, withJson);
