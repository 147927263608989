import { GetState, SetState } from 'zustand';
import { OpenAPIV3 } from 'openapi-types';

import { State } from './store';
import { getAuthHeaders } from './auth';
import { baseApiPath, ROLES } from '../constants';
import { IOIDCConfig } from './models/oidc';
import { IMe } from './models/auth';

const BaseGet = <T>(path: string, token: string | null = null, withJson = true): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fetch(`${baseApiPath}${path}`, { headers: getAuthHeaders(token) })
      .then((res: Response) => {
        if (res.status > 299) {
          return reject();
        }
        if (withJson) {
          return res.json().then(resolve).catch(reject);
        }
        resolve(res.body as unknown as T);
      })
      .catch(reject);
  });
};

export const baseGetAuthConfig = async (set: SetState<State>): Promise<IOIDCConfig> => {
  return new Promise<IOIDCConfig>((resolve, reject) => {
    BaseGet<IOIDCConfig>('/auth-user/.well-known/openid-configuration')
      .then((oidc: IOIDCConfig) => {
        set({ oidc });
        resolve(oidc);
      })
      .catch(reject);
  });
};

export const baseGetMe = async (set: SetState<State>, get: GetState<State>): Promise<IMe> => {
  const state: State = get();
  return new Promise<IMe>((resolve, reject) => {
    BaseGet<IMe>('/auth-user/me', state.keycloak?.token)
      .then((me: IMe) => {
        let isSystemUser = false;
        let isSystemRoot = false;
        if (me?.roles?.includes(ROLES.SYSTEM_ROOT) || me?.roles?.includes(ROLES.SUPPORT)) {
          isSystemUser = true;
        }
        if (me?.roles?.includes(ROLES.SYSTEM_ROOT)) {
          isSystemRoot = true;
        }
        set({ me, isSystemUser, isSystemRoot });
        resolve(me);
      })
      .catch(reject);
  });
};

export const baseGetOpenAPI = async (
  set: SetState<State>,
  get: GetState<State>,
  path: string,
): Promise<OpenAPIV3.Document> => {
  const state: State = get();
  return new Promise<OpenAPIV3.Document>((resolve, reject) => {
    BaseGet<OpenAPIV3.Document>(`/docs/openapi.json?path=${path}`, state.keycloak?.token)
      .then((openapi: OpenAPIV3.Document) => {
        set({ openapi });
        resolve(openapi);
      })
      .catch(reject);
  });
};
