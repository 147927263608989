import { Card, Pagination } from 'antd';
import 'antd/dist/antd.css';
import MainListExtra from './MainListExtra';
import { FilePdfOutlined } from '@ant-design/icons';
import { useStore } from '../../store/store';
import MainTitle from './MainTitle';
import { CSSProperties } from 'react';

interface Props {
  children: any;
  loading: boolean;
  title: string;
  pagination?: boolean;
  titleIcon?: JSX.Element;
  actions?: JSX.Element[];
}

const MainList = ({ title, titleIcon, children, loading, pagination = true, actions }: Props): JSX.Element => {
  const page: number = useStore((state) => state.pagination.page);
  const pageSize: number = useStore((state) => state.pagination.pageSize);
  const itemCount: number = useStore((state) => state.pagination.itemCount);

  const setPageSize = useStore((state) => state.setPageSize);
  const setPage = useStore((state) => state.setPage);

  const _actions: JSX.Element[] = actions || [];
  if (pagination) {
    _actions.push(
      <Pagination
        key="pagination"
        pageSize={pageSize}
        current={page}
        defaultCurrent={1}
        total={itemCount}
        showSizeChanger={true}
        onChange={setPage}
        onShowSizeChange={setPageSize}
        pageSizeOptions={['10', '25', '50', '100']}
      />,
    );
  }

  const bodyStyle: CSSProperties = {
    height: `calc(100% - ${_actions.length ? 120 : 64}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  return (
    <Card
      title={<MainTitle title={title} icon={titleIcon || <FilePdfOutlined />}></MainTitle>}
      extra={<MainListExtra />}
      bordered
      loading={loading}
      bodyStyle={bodyStyle}
      actions={_actions}
      style={{ maxWidth: '2000px', width: '100%', height: '100%' }}
    >
      {children}
    </Card>
  );
};
export default MainList;
