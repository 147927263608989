import { Empty } from 'antd';
import { CSSProperties } from 'react';

interface IProps {
  style?: CSSProperties;
}

const Placeholder = ({ style }: IProps): JSX.Element => (
  <div
    style={{
      width: '100%',
      height: 'calc(100% - 8px)',
      marginTop: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #dce0e6',
      ...(style || {}),
    }}
  >
    <Empty description={false} />
  </div>
);

export default Placeholder;
