import { State } from './store';
import { getAuthHeaders } from './auth';
import { dsApiPath } from '../constants';
import { DEFAULT_SEARCHKEYS } from './defaults';
import { GetState, SetState } from 'zustand';
import { IPaginatedResponse } from './models/keycloak';
import { IPagination } from './models/pagination';
import { IShare, IWriteTypes, IGETResponse, ICount } from './models/dataservice';

const _dsGet = <T>(path: string, token: string | null = null, withJson = true): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fetch(`${dsApiPath}${path}`, { headers: getAuthHeaders(token) })
      .then((res: Response) => {
        if (res.status > 299) {
          return reject();
        }
        if (withJson) {
          return res.json().then(resolve).catch(reject);
        }
        resolve(res.body as unknown as T);
      })
      .catch(reject);
  });
};

const _dsBody = <T>(
  method: string,
  path: string,
  token: string | null = null,
  data?: any,
  withJson = false,
): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fetch(`${dsApiPath}${path}`, {
      method: method,
      headers: {
        ...getAuthHeaders(token),
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
      body: data ? JSON.stringify(data) : undefined,
    })
      .then((res: Response) => {
        if (res.status > 299) {
          return reject();
        }
        if (withJson) {
          return res.json().then(resolve).catch(reject);
        }
        resolve(res.body as unknown as T);
      })
      .catch(reject);
  });
};

const dsList = <T>(
  resource: string,
  pagination: IPagination,
  token: string | null = null,
  query?: string,
): Promise<IPaginatedResponse<T>> => {
  return new Promise<IPaginatedResponse<T>>((resolve, reject) => {
    const path = `/${resource}?pg_len=${pagination.pageSize}&pg=${pagination.page - 1}${
      query ? '&query=' + query : ''
    }`;
    _dsGet<Array<T>>(path, token, true)
      .then((data: Array<T>) => {
        pagination.itemCount = pagination.pageSize * pagination.page;
        if (data.length === pagination.pageSize) {
          pagination.itemCount++;
        }
        resolve({ data, pagination });
      })
      .catch(reject);
  });
};

const dsGet = <T>(resource: string, id: string, token: string | null = null): Promise<T> =>
  _dsGet<T>(`/${resource}/${id}`, token, true);

const dsPut = <T>(resource: string, id: string, token: string | null = null, data?: any): Promise<T> =>
  _dsBody<T>('PUT', `/${resource}/${id}`, token, data, true);

// const dsPatch = <T extends any>(resource: string, id: string, token: string | null = null, data?: any): Promise<T> => _dsBody<T>('PATCH', `/${resource}/${id}`, token, data, true);

const dsPost = <T>(resource: string, token: string | null = null, data?: any): Promise<T> =>
  _dsBody<T>('POST', `/${resource}`, token, data, true);

const dsDelete = (resource: string, id: string, token: string | null = null): Promise<void> =>
  _dsBody<void>('DELETE', `/${resource}/${id}`, token);

const dsShareGet = (resource: string, id: string, token: string | null = null): Promise<IShare> =>
  _dsGet<IShare>(`/${resource}/${id}/share`, token, true);

const dsSharePut = (resource: string, id: string, token: string | null = null, data?: IShare): Promise<IShare> =>
  _dsBody<IShare>('PATCH', `/${resource}/${id}/share`, token, data, true);

export const dsPatchShareMongoRes = async (set: SetState<State>, get: GetState<State>): Promise<IShare | void> => {
  const state: State = get();
  if (!state.selectedSharing?.resource_id || !state.selectedSharing?.resource) {
    console.error('No resource identification found for PATCH sharing.');
    return;
  }
  set(() => ({
    pendingAPICall: { ...state.pendingAPICall, dsSharePatch: true },
  }));
  const resource: string = state.selectedSharing?.resource as string;
  const resource_id: string = state.selectedSharing?.resource_id as string;
  const share: IShare = {
    users: state.selectedSharing?.users,
    groups: state.selectedSharing?.groups,
    roles: state.selectedSharing?.roles,
    owner_id: state.selectedSharing?.owner || state.selectedSharing?.owner_id,
  };
  return new Promise<IShare | void>((resolve, reject) => {
    dsSharePut(resource, resource_id, state.keycloak?.token, share)
      .then((share: IShare) => {
        set(() => ({ selectedSharing: { ...share, resource, resource_id } }));
        resolve(share);
      })
      .catch((errors) => {
        set(() => ({
          error: `Failed to update permissions for single ${resource} on server.`,
        }));
        reject(errors);
      })
      .finally(() => {
        set((s: State) => ({
          pendingAPICall: { ...s.pendingAPICall, dsSharePatch: false },
        }));
      });
  });
};

export const dsListMongoRes = async <T extends { id?: string }>(
  set: SetState<State>,
  get: GetState<State>,
  resource: string,
  storeKey?: keyof State,
  searchKeys?: string[],
  query?: string,
): Promise<Array<T> | void> => {
  const state: State = get();
  if (state.pendingAPICall.dsList && storeKey) {
    return;
  }
  set(() => ({ pendingAPICall: { ...state.pendingAPICall, dsList: true } }));
  const _searchKeys: string[] = searchKeys || DEFAULT_SEARCHKEYS[resource] || ['name'];
  const searchKeyString: string = _searchKeys.map((i) => `"${i}"`).join(',');
  const search: string = state.searchString ? `[${searchKeyString}]-match-all-["${state.searchString}"]` : '';

  let _query: string;
  if (query && search) {
    _query = `${search};${query}`;
  } else {
    _query = search || query || '';
  }

  return new Promise<Array<T>>((resolve, reject) => {
    dsList<T>(resource, state.pagination, state.keycloak?.token, _query)
      .then((res: IPaginatedResponse<T>) => {
        if (storeKey) {
          set(() => ({
            ...{ [storeKey]: res.data },
            pagination: res.pagination,
          }));
        }
        resolve(res.data);
      })
      .catch((error) => {
        set(() => ({
          error: `Failed to load list of ${resource} from server.`,
        }));
        reject(error);
      })
      .finally(() => {
        set((s: State) => ({
          pendingAPICall: { ...s.pendingAPICall, dsList: false },
        }));
      });
  });
};

export const dsGetMongoResNoShare = async <T extends { id?: string }>(
  set: SetState<State>,
  get: GetState<State>,
  resource: string,
  id: string,
  storeKey?: keyof State,
): Promise<T | void> => {
  const state: State = get();
  if (storeKey) {
    if (state.pendingAPICall.dsGet) {
      return;
    }
    set(() => ({
      pendingAPICall: { ...state.pendingAPICall, dsGet: true },
    }));
  }
  return new Promise<T | void>((resolve, reject) => {
    dsGet<T>(resource, id, state.keycloak?.token)
      .then((data: T) => {
        if (storeKey) {
          set((s: State) => ({
            ...s,
            ...{ [storeKey]: data },
          }));
        }
        resolve(data);
      })
      .catch((errors) => {
        set(() => ({
          error: `Failed to load single resource ${resource} from server.`,
        }));
        reject(errors);
      })
      .finally(() => {
        if (storeKey) {
          set((s: State) => ({
            pendingAPICall: {
              ...s.pendingAPICall,
              dsGet: false,
            },
          }));
        }
      });
  });
};

export const dsGetMongoRes = async <T extends { id?: string }>(
  set: SetState<State>,
  get: GetState<State>,
  resource: string,
  id: string,
  storeKey?: keyof State,
): Promise<IGETResponse<T> | void> => {
  const state: State = get();
  if (storeKey) {
    if (state.pendingAPICall.dsGet || state.pendingAPICall.dsShareGet) {
      return;
    }
    set(() => ({
      pendingAPICall: { ...state.pendingAPICall, dsShareGet: true, dsGet: true },
    }));
  }
  return new Promise<IGETResponse<T> | void>((resolve, reject) => {
    return Promise.all([dsGet<T>(resource, id, state.keycloak?.token), dsShareGet(resource, id, state.keycloak?.token)])
      .then(([data, share]) => {
        if (storeKey) {
          set(() => ({
            ...{ [storeKey]: data },
            selectedSharing: { ...share, resource: resource, resource_id: id },
          }));
        }
        resolve({ data, share });
      })
      .catch((errors) => {
        set(() => ({
          error: `Failed to load single resource ${resource} from server.`,
        }));
        reject(errors);
      })
      .finally(() => {
        if (storeKey) {
          set((s: State) => ({
            pendingAPICall: {
              ...s.pendingAPICall,
              dsShareGet: false,
              dsGet: false,
            },
          }));
        }
      });
  });
};

export const dsPutMongoRes = async <T extends { id?: string }>(
  set: SetState<State>,
  get: GetState<State>,
  resource: string,
  storeKey: keyof State,
  id?: string,
): Promise<T | void> => {
  const state: State = get();
  const payload: T = state[storeKey] as T;
  if (!payload || state.pendingAPICall.dsPut) {
    return;
  }
  set(() => ({ pendingAPICall: { ...state.pendingAPICall, dsPut: true } }));
  const _id: string = id || (payload.id as string);
  return new Promise<T | void>((resolve, reject) => {
    dsPut<T>(resource, _id, state.keycloak?.token, payload)
      .then((data: T) => {
        set((s: State) => ({ ...s, ...{ [storeKey]: data } }));
        resolve(data);
      })
      .catch((errors) => {
        set(() => ({
          error: `Failed to update single resource ${resource} on server.`,
        }));
        reject(errors);
      })
      .finally(() => {
        set((s: State) => ({
          pendingAPICall: { ...s.pendingAPICall, dsPut: false },
        }));
      });
  });
};

export const dsPostMongoRes = async <T extends { id?: string }>(
  set: SetState<State>,
  get: GetState<State>,
  resource: string,
  storeKey: keyof State,
): Promise<T | void> => {
  const state: State = get();
  const payload: T = state[storeKey] as T;
  if (!payload || state.pendingAPICall.dsPost) {
    return;
  }
  set(() => ({ pendingAPICall: { ...state.pendingAPICall, dsPost: true } }));
  return new Promise<T>((resolve, reject) => {
    dsPost<T>(resource, state.keycloak?.token, payload)
      .then((data: T) => {
        set((s: State) => ({ ...s, ...{ [storeKey]: data } }));
        resolve(data);
      })
      .catch((error) => {
        set(() => ({
          error: `Failed to create single resource ${resource} on server.`,
        }));
        reject(error);
      })
      .finally(() => {
        set((s: State) => ({
          pendingAPICall: { ...s.pendingAPICall, dsPost: false },
        }));
      });
  });
};

export const dsDeleteMongoRes = async <T extends { id?: string }>(
  set: SetState<State>,
  get: GetState<State>,
  resource: string,
  storeKey: keyof State,
  id?: string,
): Promise<void> => {
  const state: State = get();
  const payload: T = state[storeKey] as T;
  if (!payload || state.pendingAPICall.dsDelete) {
    return;
  }
  set(() => ({ pendingAPICall: { ...state.pendingAPICall, dsDelete: true } }));
  const _id: string = id || (payload.id as string);
  return new Promise<void>((resolve, reject) => {
    dsDelete(resource, _id, state.keycloak?.token)
      .then(() => {
        set((s: State) => ({ ...s, ...{ [storeKey]: undefined } }));
        resolve();
      })
      .catch((error) => {
        set(() => ({
          error: `Failed to delete single resource ${resource} on server.`,
        }));
        reject(error);
      })
      .finally(() => {
        set((s: State) => ({
          pendingAPICall: { ...s.pendingAPICall, dsDelete: false },
        }));
      });
  });
};

export const dsWriteMongoRes = async <T extends { id?: string }>(
  set: SetState<State>,
  get: GetState<State>,
  type: IWriteTypes,
  resource: string,
  storeKeySingle: keyof State,
  storeKeyList?: keyof State,
  id?: string,
): Promise<T | { id?: string | undefined } | void> => {
  set(() => ({ searchString: '' }));
  const apiMethod = {
    create: dsPostMongoRes,
    update: dsPutMongoRes,
    delete: dsDeleteMongoRes,
  }[type];
  return new Promise<T | { id?: string | undefined } | void>((resolve, reject) => {
    apiMethod(set, get, resource, storeKeySingle, id)
      .then((data) => {
        if (storeKeyList) {
          dsListMongoRes(set, get, resource, storeKeyList);
        }
        resolve(data);
      })
      .catch(reject);
  });
};

export const dsCountMongoRes = async (
  set: SetState<State>,
  get: GetState<State>,
  resource: string,
  query?: string,
  storeKey?: keyof State,
): Promise<ICount | void> => {
  const state: State = get();
  if (storeKey) {
    if (state.pendingAPICall.dsCount) {
      return;
    }
    set(() => ({ pendingAPICall: { ...state.pendingAPICall, dsCount: true } }));
  }
  let url = `/${resource}/count?pg_len=10`;
  if (query) {
    url = `${url}&query=${query}`;
  }
  return new Promise<ICount>((resolve, reject) => {
    _dsGet<ICount>(url, state.keycloak?.token, true)
      .then((count: ICount) => {
        if (storeKey) {
          set((state: State) => ({ ...state, ...{ [storeKey]: count } }));
        }
        resolve(count);
      })
      .catch((error) => {
        set(() => ({
          error: `Failed to load count of ${resource} from server.`,
        }));
        reject(error);
      })
      .finally(() => {
        if (storeKey) {
          set((s: State) => ({
            pendingAPICall: { ...s.pendingAPICall, dsCount: false },
          }));
        }
      });
  });
};
