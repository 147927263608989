import { State } from './store';
import { IGroup, IPaginatedResponse, IRole, IUser, RawGroup } from './models/keycloak';
import { GetState, SetState } from 'zustand';
import { KCPaginatedList as _KCPaginatedList, KCGet as _KCGet, KCBody as _KCBody } from './kcBaseApiActions';
import { IPagination } from './models/pagination';
import { flattenGroups } from '../utils/groups';

export const KCFlexiblePaginatedList = async <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  storeKey?: keyof State,
  useSearch = false,
  overridePagination?: IPagination,
): Promise<IPaginatedResponse<T> | void> => {
  const state: State = get();
  if (state.pendingAPICall.kcList && storeKey) {
    return;
  }
  if (storeKey) {
    set((s: State) => ({ pendingAPICall: { ...s.pendingAPICall, kcList: true } }));
  }
  let url: string = path;
  if (useSearch && state.searchString) {
    url = `${url}${url.includes('?') ? '&' : '?'}search=${state.searchString}`;
  }
  return new Promise<IPaginatedResponse<T>>((resolve, reject) => {
    _KCPaginatedList<T>(url, overridePagination || state.pagination, state.keycloak?.token)
      .then((res: IPaginatedResponse<T>) => {
        if (storeKey) {
          set((state: State) => ({ ...state, ...{ [storeKey]: res.data } }));
        }
        if (!overridePagination) {
          set(() => ({ pagination: res.pagination }));
        }
        resolve(res);
      })
      .catch((error) => {
        set(() => ({ error: `Failed to load list of ${path} from server.` }));
        reject(error);
      })
      .finally(() => {
        if (storeKey) {
          set((s: State) => ({ pendingAPICall: { ...s.pendingAPICall, kcList: false } }));
        }
      });
  });
};

export const KCPaginatedList = async <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  storeKey?: keyof State,
  useSearch = false,
): Promise<Array<T> | void> => {
  return new Promise<Array<T> | void>((resolve, reject) => {
    KCFlexiblePaginatedList<T>(set, get, path, storeKey, useSearch)
      .then((res: IPaginatedResponse<T> | void) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const KCOverridePaginatedList = async <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  storeKey?: keyof State,
  useSearch = false,
  overridePagination?: IPagination,
): Promise<Array<T> | void> => {
  return new Promise<Array<T> | void>((resolve, reject) => {
    KCFlexiblePaginatedList<T>(set, get, path, storeKey, useSearch, overridePagination)
      .then((res: IPaginatedResponse<T> | void) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const KCGet = async <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  storeKey?: keyof State,
): Promise<T | void> => {
  const state: State = get();
  if (state.pendingAPICall.kcGet && storeKey) {
    return;
  }
  if (storeKey) {
    set((s: State) => ({ pendingAPICall: { ...s.pendingAPICall, kcGet: true } }));
  }
  return new Promise<T | void>((resolve, reject) => {
    KCGetNoPending<T>(set, get, path, storeKey)
      .then(resolve)
      .catch(reject)
      .finally(() => {
        if (storeKey) {
          set((s: State) => ({ pendingAPICall: { ...s.pendingAPICall, kcGet: false } }));
        }
      });
  });
};

export const KCGetNoPending = async <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  storeKey?: keyof State,
  timeout?: number,
): Promise<T | void> => {
  const state: State = get();
  return new Promise<T>((resolve, reject) => {
    _KCGet<T>(path, state.keycloak?.token, true, timeout)
      .then((res: T) => {
        if (storeKey) {
          set((s: State) => ({ ...s, [storeKey]: res }));
        }
        resolve(res);
      })
      .catch((error) => {
        set(() => ({ error: `Failed to load list of ${path} from server.` }));
        reject(error);
      });
  });
};

const KCBody = async <T>(
  method: 'PUT' | 'POST' | 'DELETE',
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  data?: any,
  storeKey?: keyof State,
  withJson = false,
): Promise<T | void> => {
  const pendingAPICallKey: 'kcPut' | 'kcPost' | 'kcDelete' = `kc${method[0]}${method
    .slice(1, method.length)
    .toLowerCase()}` as any;
  const state: State = get();
  if (state.pendingAPICall[pendingAPICallKey]) {
    return;
  }
  set((s: State) => ({ pendingAPICall: { ...s.pendingAPICall, [pendingAPICallKey]: true } }));
  return new Promise<T>((resolve, reject) => {
    _KCBody<T>(method, state.keycloak?.token, path, data, withJson)
      .then((res: T) => {
        if (storeKey) {
          set((s: State) => ({ ...s, [storeKey]: res }));
        }
        resolve(res);
      })
      .catch((error) => {
        set(() => ({ error: `Failed to update single resource ${path} on server.` }));
        reject(error);
      })
      .finally(() => {
        set((s: State) => ({ pendingAPICall: { ...s.pendingAPICall, [pendingAPICallKey]: false } }));
      });
  });
};

export const KCPut = <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  data?: unknown,
  storeKey?: keyof State,
  withJson = false,
): Promise<T | void> => KCBody<T>('PUT', set, get, path, data, storeKey, withJson);

export const KCPost = <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  data?: unknown,
  storeKey?: keyof State,
  withJson = false,
): Promise<T | void> => KCBody<T>('POST', set, get, path, data, storeKey, withJson);

export const KCDelete = <T>(
  set: SetState<State>,
  get: GetState<State>,
  path: string,
  data?: unknown,
  withJson = false,
): Promise<T | void> => KCBody<T>('DELETE', set, get, path, data, undefined, withJson);

export const kcSearchUserById = (set: SetState<State>, get: GetState<State>, id: string): Promise<IUser | void> => {
  return new Promise<IUser | void>((resolve) => {
    KCGet<IUser>(set, get, `/users/${id}`)
      .then(resolve)
      .catch(() => resolve());
  });
};

export const kcSearchUserByEmail = (
  set: SetState<State>,
  get: GetState<State>,
  searchString: string,
): Promise<IUser[]> => {
  return new Promise<IUser[]>((resolve) => {
    KCGet<IUser[]>(set, get, `/users?first=0&max=10&search=${searchString}`)
      .then((res) => resolve(res || []))
      .catch(() => resolve([]));
  });
};

export const kcSearchGroup = (
  set: SetState<State>,
  get: GetState<State>,
  searchString: string,
  includeRoot = false,
): Promise<IGroup[]> => {
  return new Promise<IGroup[]>((resolve) => {
    KCGetNoPending<RawGroup[]>(set, get, `/groups?first=0&max=5&search=${searchString}`)
      .then((partialGrps: RawGroup[] | void) => {
        const calls: Promise<RawGroup | void>[] = (partialGrps || []).map((g) =>
          KCGetNoPending<RawGroup>(set, get, `/groups/${g.id}`),
        );
        Promise.all(calls).then((res: Array<RawGroup | void>) => {
          const fullGrps: RawGroup[] = res.filter((r) => !!r) as RawGroup[];
          resolve(fullGrps ? flattenGroups(fullGrps, includeRoot) : []);
        });
      })
      .catch(() => resolve([]));
  });
};

export const kcSearchRole = (set: SetState<State>, get: GetState<State>, searchString: string): Promise<IRole[]> => {
  return new Promise<IRole[]>((resolve) => {
    KCGetNoPending<IRole[]>(set, get, `/roles?first=0&max=20&search=${searchString}`)
      .then((grps) => resolve(grps || []))
      .catch(() => resolve([]));
  });
};
