import { CSSProperties, useEffect } from 'react';

import { Table, Button } from 'antd';
import { useStore } from '../../store/store';
import { IComponent } from '../../store/models/dataservice';
import { IPagination } from '../../store/models/pagination';

interface IProps {
  style?: CSSProperties;
}

const ComponentsList = ({ style }: IProps): JSX.Element => {
  const isListLoading = useStore((state) => state.pendingAPICall.dsList);
  const pagination: IPagination = useStore((state) => state.pagination);
  const isAuth: boolean = useStore((state) => state.isAuthenticated);
  const searchString: string = useStore((state) => state.searchString);
  const components: Array<IComponent> = useStore((state) => state.components || []);
  const selectedComponent: IComponent | undefined = useStore((state) => state.selectedComponent);

  const dsListComponents = useStore((state) => state.dsListComponents);
  const dsGetComponent = useStore((state) => state.dsGetComponent);
  const unselectItem = useStore((state) => state.unselectItem);

  useEffect(() => {
    if (isAuth) {
      dsListComponents();
    }
  }, [pagination, searchString, isAuth]);

  const selectComponent = (component: IComponent) => {
    if (!component.id) {
      return;
    }
    unselectItem();
    dsGetComponent(component.id);
  };

  const componentsColumns = [
    {
      title: 'Part Number',
      dataIndex: 'part_number',
      key: 'part_number',
    },
    {
      title: 'Action',
      key: 'part_number',
      render: (c: IComponent) => (
        <Button
          key={c.id}
          onClick={() => selectComponent(c)}
          disabled={(selectedComponent as IComponent | undefined)?.id === c.id}
        >
          Select
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: '100%', overflow: 'auto', ...(style || {}) }}>
      <Table
        loading={isListLoading}
        size="small"
        bordered
        dataSource={components}
        columns={componentsColumns}
        pagination={false}
      />
    </div>
  );
};

export default ComponentsList;
