import { Row, Col, Spin } from 'antd';

import { useStore } from '../../store/store';
import MainList from '../MainList/MainList';
import DatasheetViewer from './DatasheetViewer';
import ComponentsList from './ComponentsList';
import DetectImages from './DetectImages';
import MobileSeparator from './MobileSeparator';

const DatasheetsList = (): JSX.Element => {
  const isLoading = useStore(
    (state) => state.pendingAPICall.kiresys || state.pendingAPICall.dsList || state.pendingAPICall.dsGet,
  );

  return (
    <>
      <MainList title="Datasheets" titleIcon={isLoading ? <Spin size="small"></Spin> : undefined} loading={false}>
        <Row gutter={8} style={{ height: '100%' }}>
          <Col md={24} lg={6} style={{ height: '100%' }}>
            <ComponentsList />
          </Col>
          <MobileSeparator />
          <Col md={24} lg={9} style={{ height: '100%' }}>
            <DatasheetViewer />
          </Col>
          <MobileSeparator />
          <Col md={24} lg={9} style={{ height: '100%' }}>
            <DetectImages />
          </Col>
        </Row>
      </MainList>
    </>
  );
};

export default DatasheetsList;
