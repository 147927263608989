import Keycloak, { KeycloakInstance } from 'keycloak-js';
import { kcAuthUri, kcLoginClientId, kcRealm } from '../constants';
import { GetState, SetState } from 'zustand';
import { State } from './store';
import { IOIDCConfig } from './models/oidc';
import { AxiosRequestHeaders, AxiosResponseHeaders } from 'axios';

export const getAuthHeaders = (access_token: string | null): HeadersInit => {
  const headers: HeadersInit = {};
  if (access_token) {
    headers['Authorization'] = `Bearer ${access_token}`;
  }
  return headers;
};

export const getAxiosHeaders = (
  access_token: string | null,
  extraHeaders?: AxiosResponseHeaders,
): AxiosRequestHeaders => {
  const headers: AxiosRequestHeaders = extraHeaders || {};
  if (access_token) {
    headers['Authorization'] = `Bearer ${access_token}`;
  }
  return headers;
};

export const createKeycloak = async (set: SetState<State>, get: GetState<State>): Promise<KeycloakInstance> => {
  const oidc: IOIDCConfig = get().oidc as IOIDCConfig;
  const regex = new RegExp('^(?<uri>.*)/realms/(?<realm>.*)$');

  const { uri, realm } = oidc.issuer?.match(regex)?.groups as { uri: string; realm: string };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const keycloak = new Keycloak({
    url: uri || kcAuthUri,
    realm: realm || kcRealm,
    clientId: kcLoginClientId,
  });
  set({ keycloak: keycloak });
  return keycloak;
};

export const getTokenOrLogin = (set: SetState<State>, get: GetState<State>): Promise<string> => {
  set({ pendingApiResponse: true });
  const keycloak: KeycloakInstance = get().keycloak as KeycloakInstance;
  return new Promise<string>((resolve, reject) => {
    keycloak
      .init({ onLoad: 'login-required' })
      .then((status: boolean) => {
        if (status) {
          set({ isAuthenticated: true });
          resolve(keycloak.token as string);
        } else {
          set({ error: 'Authentication server returned an invalid token' });
          set({ isAuthenticated: false });
          reject();
        }
      })
      .catch(() => {
        set({ error: 'Failed to obtain a token from the authentication server' });
      })
      .finally(() => set({ pendingApiResponse: false }));
  });
};
