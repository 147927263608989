import { Space } from 'antd';

interface Props {
  title: string;
  icon: JSX.Element;
}

const MainTitle = ({ title, icon }: Props): JSX.Element => {
  return (
    <Space style={{ maxWidth: '50vw' }}>
      {icon}
      <div
        style={{ maxWidth: 'calc(50vw - 60px)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {title}
      </div>
    </Space>
  );
};
export default MainTitle;
