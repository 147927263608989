import { CSSProperties } from 'react';

import { Divider } from 'antd';
import './styles.css';

interface IProps {
  style?: CSSProperties;
}

const MobileSeparator = ({ style }: IProps): JSX.Element => {
  return <Divider style={style} className="hide-on-desktop" />;
};

export default MobileSeparator;
